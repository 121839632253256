import colors from "vuetify/es5/util/colors";
const isDev = process.env.NODE_ENV !== "production";

export default {
  // Global page headers: https://go.nuxtjs.dev/config-head
  head: {
    titleTemplate: "%s - master-chief",
    title: "master-chief",
    htmlAttrs: {
      lang: "en"
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1"
      },
      {
        hid: "description",
        name: "description",
        content: ""
      },
      {
        name: "format-detection",
        content: "telephone=no"
      }
    ],
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico"
      }
    ]
  },

  // Global CSS: https://go.nuxtjs.dev/config-css
  css: [],

  // Plugins to run before rendering page: https://go.nuxtjs.dev/config-plugins
  plugins: [
    "~/plugins/Permission.ts",
    "~/plugins/PermissionPageMixin.ts",
    "~/plugins/PermissionDirective.ts",
    "~/plugins/vue-tooltips.js",
    { src: "./plugins/Snowplow.ts", ssr: false }
  ],

  // Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
  buildModules: [
    // https://go.nuxtjs.dev/typescript
    "@nuxt/typescript-build",
    // https://go.nuxtjs.dev/vuetify
    "@nuxtjs/vuetify",
    // Moment.js: https://go.nuxtjs.dev/config-moment
    "@nuxtjs/moment"
  ],

  // Modules: https://go.nuxtjs.dev/config-modules
  modules: [
    // https://go.nuxtjs.dev/axios
    "@nuxtjs/axios",
    "@nuxtjs/style-resources",
    "@nuxtjs/i18n",
    "@nuxtjs/proxy",
    "@nuxtjs/sentry",
    "~/modules/health",
    // firebase: https://go.nuxtjs.dev/firebase
    [
      "@nuxtjs/firebase",
      {
        config: {
          apiKey: "AIzaSyDALg7fdfpBkHOYq5ChjFA1ZecFJQxIlu4",
          authDomain: "torre-operations-design.firebaseapp.com",
          projectId: "torre-operations-design",
          storageBucket: "torre-operations-design.appspot.com",
          messagingSenderId: "908513278413",
          appId: "1:908513278413:web:e566e3d3da9df332c4ec84",
          measurementId: "G-932N4HGN2Y"
        },
        services: {
          auth: {
            ssr: false,
            persistence: "local", // default
            initialize: {
              onAuthStateChangedAction: "onAuthStateChangedAction",
              subscribeManually: false
            }
          },
          firestore: {
            memoryOnly: false,
            enablePersistence: false
          },
          analytics: false
        }
      }
    ]
  ],

  // Axios module configuration: https://go.nuxtjs.dev/config-axios
  axios: {
    // Workaround to avoid enforcing hard-coded localhost:3000: https://github.com/nuxt-community/axios-module/issues/308
    baseURL: "/"
  },

  i18n: {
    vueI18nLoader: true,
    strategy: "prefix_and_default",
    locales: [
      {
        code: "es",
        file: "es.js"
      },
      {
        code: "en",
        file: "en.js"
      }
    ],
    lazy: true,
    langDir: "i18n/",
    defaultLocale: "en",
    vueI18n: {
      fallbackLocale: "en"
    },
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: "torre.locale"
    }
  },

  // Build Configuration: https://go.nuxtjs.dev/config-build
  build: {
    extractCSS: true,
    optimization: {
      splitChunks: {
        chunks: "all",
        automaticNameDelimiter: ".",
        maxSize: 51200
      }
    },
    transpile: [
      "lodash-es",
      ({ isLegacy }) => isLegacy && "axios"
    ]
  },

  // pwa: https://go.nuxtjs.dev/config-pwa
  pwa: {
    meta: false,
    icon: false,
    // manifest: false,

    workbox: {
      importScripts: ["/firebase-auth-sw.js"],
      // by default the workbox module will not install the service worker in dev environment to avoid conflicts with HMR
      // only set this true for testing and remember to always clear your browser cache in development
      // dev: process.env.NODE_ENV === "development",
      dev: true
    }
  },

  // ssr: https://go.nuxtjs.dev/config-ssr
  ssr: false,

  // Vuetify module configuration: https://go.nuxtjs.dev/config-vuetify
  vuetify: {
    customVariables: ["~/assets/variables.scss"],
    theme: {
      dark: true,
      themes: {
        dark: {
          primary: colors.lime.darken3,
          accent: colors.grey.darken3,
          secondary: colors.amber.darken3,
          info: colors.teal.lighten1,
          warning: colors.amber.base,
          error: colors.deepOrange.accent4,
          success: colors.green.accent3
        }
      }
    }
  },

  // router: https://go.nuxtjs.dev/config-router
  router: {
    middleware: ["auth", "unsavedChangesHelper"]
  },

  // Server: https://go.nuxtjs.dev/config-server
  server: {
    port: process.env.TORRE_APP_SERVER_PORT || 3535
  },

  // serverMiddleware: https://go.nuxtjs.dev/config-serverMiddleware
  // serverMiddleware: !isDev ? [] : ["~/api/hello.ts"],
  serverMiddleware: [],

  proxy: {
    "/api": { target: isDev ? "http://localhost:14000" : process.env.CORTANA_URL }
  },

  // env variables: https://go.nuxtjs.dev/config-env
  env: {
    bioUrl: process.env.BIO_URL,
    discoveryBaseUrl: !isDev ? process.env.DISCOVERY_URL : "http://localhost:9000",
    jobPostBaseUrl: process.env.JOB_POST_BASE_URL || "https://torre.ai/post",
    cortanaBaseUrl: !isDev ? process.env.CORTANA_URL : "http://localhost:14000",
    firebaseApiKey: process.env.FIREBASE_API_KEY,
    firebaseAuthDomain: process.env.FIREBASE_AUTH_DOMAIN,
    firebaseProjectId: process.env.FIREBASE_PROJECT_ID,
    firebaseStorageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    firebaseMessagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    firebaseAppId: process.env.FIREBASE_APP_ID,
    firebaseMeasurementId: process.env.FIREBASE_MEASUREMENT_ID,
    metabaseBaseUrl: process.env.METABASE_BASE_URL || "https://orthanc.torre.ai",
    googleMapsUrl: `https://maps.googleapis.com/maps/api/js?key=${process.env.MASTER_CHIEF_GOOGLE_SERVICES_API_KEY}&libraries=places`,
    snowPlowAppId: process.env.VUE_APP_MASTER_CHIEF_SNOWPLOW_ID || "master-chief-development",
    snowPlowCollectorUrl: process.env.VUE_APP_MASTER_CHIEF_SNOWPLOW_COLLECTOR_URL || "snowplow_collector_url",
    configcatSDKKey: process.env.VUE_APP_MASTER_CHIEF_CONFIGCAT_SDK_KEY || "X3TZCP_q_EaDTc_sk55LFQ/t8PyrtbdMke2nUU8GIGhAg"
  },
  // publicRuntimeConfig: https://go.nuxtjs.dev/config-public-runtime-config
  publicRuntimeConfig: {
    bioUrl: process.env.BIO_URL,
    discoveryBaseUrl: process.env.DISCOVERY_URL || "http://localhost:9000",
    cortanaBaseUrl: !isDev ? process.env.CORTANA_URL : "http://localhost:14000",
    jobPostBaseUrl: process.env.JOB_POST_BASE_URL || "http://localhost:12000/post",
    genomeBaseUrl: process.env.TORRE_BASE_URL || "http://localhost:3000",
    googleMapsUrl: `https://maps.googleapis.com/maps/api/js?key=${process.env.MASTER_CHIEF_GOOGLE_SERVICES_API_KEY}&libraries=places`,
    cloudinaryCloudName: process.env.TORRE_CLOUDINARY_CLOUD_NAME || "torre-technologies-co",
    cloudinaryDataPointPreset: process.env.TORRE_CLOUDINARY_DATA_POINT_PRESET || "master-chief.torre.co.data-points-dev-media",
    firebaseApiKey: process.env.FIREBASE_API_KEY,
    firebaseAuthDomain: process.env.FIREBASE_AUTH_DOMAIN,
    firebaseProjectId: process.env.FIREBASE_PROJECT_ID,
    firebaseStorageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    firebaseCortanaCollectionName: "cortana",
    firebaseTasksDocumentName: "tasks",
    firebaseMessagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    firebaseAppId: process.env.FIREBASE_APP_ID,
    firebaseMeasurementId: process.env.FIREBASE_MEASUREMENT_ID,
    metabaseBaseUrl: process.env.METABASE_BASE_URL || "https://orthanc.torre.ai",
    notificationSoundURL: "https://res.cloudinary.com/torre-technologies-co/video/upload/v1743106654/origin/master-chief/jczmmqrvqfnzdmhpnv79.mp3",
    octopusAudioUrl: "https://res.cloudinary.com/torre-technologies-co/video/upload/v1691419771/dev/operations/master-chief/octopus-new-task-assigned-notification.mp3"
  },

  sentry: {
    disabled: (process.env.TORRE_SENTRY_STATUS || "disabled") === "disabled",
    config: {
      environment: process.env.SENTRY_ENVIRONMENT || "development"
    },
    publishRelease: process.env.TORRE_SENTRY_PUBLISH_RELEASE === "enabled"
      ? {
          org: "torre-co",
          project: "master-chief",
          cleanArtifacts: true,
          setCommits: {
            auto: false,
            repo: "torre-labs/master-chief",
            commit: process.env.TORRE_SENTRY_RELEASE_COMMIT_ID,
            ignoreMissing: true
          }
        }
      : false,
    tracing: {
      tracesSampleRate: 0.1,
      vueOptions: {
        tracing: true,
        tracingOptions: {
          hooks: ["activate", "mount", "update"],
          timeout: 3000,
          trackComponents: false
        }
      },
      browserOptions: {}
    }
  }
};
